<script setup lang="ts">
import SimpleImage from '@components/images/SimpleImage.vue'
defineProps({
  path: {
    type: String,
    required: true
  },
  image: {
    type: String
  },
  title: {
    type: String
  },
  description: {
    type: String
  },
  tags: {
    type: Array<String>
  },
  draft: {
    type: Boolean
  }
})

const isImageAbsolute = (url?: string) => {
  var regularExpressionForURL = /^https?:\/\//i

  if (!url) {
    return false
  }
  if (regularExpressionForURL.test(url)) {
    return true
  }

  return false
}
</script>
<template>
  <div
    class="flex flex-row overflow-hidden min-h-[9rem] md:flex-col relative w-full md:max-w-[23rem] group/item hover:shadow-xl hover:scale-[1.02] shadow-neutral-darkest rounded-2xl h-full last:duration-500"
    :class="!draft ? 'cursor-pointer' : 'opacity-65'"
  >
    <div
      class="hidden md:block aspect-square w-1/3 h-auto min-h-full md:rounded-t-2xl md:min-h-0 md:h-auto md:w-full md:aspect-[4/3] relative overflow-hidden z-10"
    >
      <div class="absolute peer w-auto md:w-full h-full md:h-auto overflow-hidden">
        <div v-if="isImageAbsolute(image)" class="md:w-full h-full">
          <div class="w-full h-full">
            <img
              :src="image"
              :alt="title"
              class="h-full w-full transition-all duration-200 group-hover/item:scale-110"
            />
          </div>
        </div>
        <div v-else>
          <div class="w-full h-full">
            <SimpleImage :src="image" :alt="title"></SimpleImage>
          </div>
        </div>
        <div
          class="z-10 px-6 py-4 w-full h-full flex items-center justify-center top-0 left-0 absolute transition-all"
          :class="
            draft
              ? 'bg-primary/[.75] opacity-100'
              : 'bg-primary/[.5] opacity-0 group-hover/item:opacity-100'
          "
        >
          <span v-if="draft" class="text-secondary text-2xl font-body -mt-6">Coming soon</span>
        </div>
      </div>
    </div>
    <div
      class="flex-1 flex -ml-1 md:ml-0 md:-mt-1 flex-col md:rounded-b-2xl px-6 md:px-6 py-6 md:py-8 relative z-10"
      :class="draft ? 'bg-neutral-dark' : 'bg-primary'"
    >
      <span v-if="draft" class="text-secondary">Coming soon</span>
      <h3 class="font-body text-lg md:text-xl font-bold mb-2 md:mb-4 text-white glow:text-glow">
        {{ title }}
      </h3>
      <p class="font-body hidden md:block md:text-md text-white mb-2 md:mb-4">
        {{ description }}
      </p>
      <div v-if="tags" class="mb-2 md:mb-4 md:flex items-end md:flex-1">
        <ul class="flex flex-row gap-1 flex-wrap">
          <li
            v-for="(tag, index) in tags"
            :key="index"
            class="px-2 py-1 text-neutral-light bg-primary-lightest rounded-sm font-body text-xs md:text-sm"
          >
            {{ tag }}
          </li>
        </ul>
      </div>
      <div class="flex-1 md:hidden flex justify-end items-end text-secondary" v-if="!draft">
        <div class="flex flex-row items-center gap-2">
          Read more
          <font-awesome-icon icon="fa-solid fa-chevron-right" class="mt-[.2rem]" size="xs" />
        </div>
      </div>
    </div>
    <a
      v-if="!draft"
      :href="path"
      :title="title"
      class="absolute w-full h-full left-0 top-0 z-20 rounded-2xl border-primary/[.15] border-4 glow glow:border-glow"
    ></a>
  </div>
</template>
