<script setup lang="ts">
import Grid from '../Grid.vue'
import Block from '../Block.vue'
import Container from '@components/Container.vue'
import { onMounted, ref } from 'vue'

const footerMargin = ref<number>()
const footer = ref<Element>()

onMounted(() => {
  if (process.client) {
    footerMargin.value = footer.value?.getBoundingClientRect().height ?? 0
  }
})
</script>

<template>
  <div>
    <div class="relative w-full block" :style="'margin-bottom:' + footerMargin + 'px;'"></div>
    <div class="fixed bottom-0 left-0 z-[-1] w-full" ref="footer">
      <Container className="bg-primary w-full flex flex-col justify-center items-center">
        <Block>
          <Grid>
            <div class="col-span-full md:col-start-1 md:col-span-2 lg:col-span-3 text-white">
              <ul class="flex flex-col gap-4 list-none text-xl md:text-lg">
                <li class="ml-0">
                  <a
                    class="flex flex-row gap-2 items-center transition-all hover:text-secondary"
                    href="https://linkedin.com/in/camisteine"
                    ><font-awesome-icon icon="fa-brands fa-linkedin" />
                    <span class="">LinkedIn</span></a
                  >
                </li>
                <li class="ml-0">
                  <a
                    class="flex flex-row gap-2 items-center transition-all hover:text-secondary"
                    href="https://github.com/camistein"
                    ><font-awesome-icon icon="fa-brands fa-github" />Github</a
                  >
                </li>
              </ul>
            </div>
            <div
              class="col-span-full md:col-start-3 lg:col-start-5 md:col-span-2 lg:col-span-4 text-white opacity-45"
            >
              <p class="font-body text-sm lg:text-md mb-2">
                This website is created in Vue & Nuxt together with Tailwind and Swiper. For icons
                I've used Devicon and FontAwesome. Blog content is retrieved from Github and indexed
                in Algolia for search and filtering.
              </p>
              <p class="font-body text-sm lg:text-md mb-2">
                Images have been AI generated on
                <a href="https://www.rawpixel.com/"> RawPixel </a> or
                <a href="https://www.canva.com/"> Canva </a>.
              </p>
            </div>
            <div class="col-span-full md:col-start-6 lg:col-start-12 md:col-span-1">
              <a
                href="#top"
                class="border-2 rounded-3xl text-white border-white flex w-6 transition-all h-6 p-4 items-center justify-center hover:text-secondary hover:border-secondary"
              >
                <font-awesome-icon icon="fa-solid fa-arrow-up" />
              </a>
            </div>
          </Grid>
        </Block>
        <div class="w-full text-white flex justify-center py-4 opacity-45">
          <span>&copy; {{ new Date().getFullYear() }}</span>
        </div>
      </Container>
    </div>
  </div>
</template>
