<script setup lang="ts">
import IconLogo from '@/components/icons/IconLogo.vue'
import InViewObserver from '../../observers/InViewObserver'
import type { Intersection } from '../../observers/InViewObserver'
import { ref, onMounted } from 'vue'

const visible = ref<boolean>(true)
const transparent = ref<boolean>(true)

const onScroll = (intersection: Intersection) => {
  if (intersection.scrollingUp) {
    visible.value = true
    if ((intersection.scrollPosition ?? 0) < 150) {
      transparent.value = true
    } else {
      transparent.value = false
    }
  } else {
    visible.value = false
  }
}

const observer = new InViewObserver((intersection: Intersection) => {}, '#top', true, onScroll)

onMounted(() => {
  if (!process.server) {
    const element = document.querySelector('#top')
    if (element) {
      observer.observe(element)
    }
  }
})
</script>

<template>
  <div
    class="fixed top-0 transition-all duration-500 w-full z-[99] hidden lg:flex justify-center py-6"
    :class="visible ? 'translate-y-0' : '-translate-y-32'"
  >
    <div
      class="rounded-full px-16 shadow-lg border border-neutral-light"
      :class="transparent ? 'bg-transparent border-transparent' : 'bg-white'"
    >
      <nav
        class="overflow-hidden duration-500 h-auto flex flex-row gap-8 px-6 items-center justify-center"
      >
        <ul
          class="flex-row flex gap-8 py-3 text-primary"
          :class="transparent ? 'text-white' : 'text-primary'"
        >
          <li class="flex justify-center items-center">
            <a
              href="/"
              class="flex gap-2 justify-center items-center group transition-all duration-300 hover:text-secondary-darker"
              title="home"
            >
              <div class="z-20 text-secondary w-8 relative overflow-visible">
                <a href="/" class="relative z-20"><IconLogo /></a>
                <div
                  class="absolute z-0 opacity-0 group-hover:opacity-100 w-4 h-4 bg-secondary shadow-glowing rounded-2xl top-1/3 left-1/3 -mt-2 -ml-1"
                ></div>
              </div>
              Home
            </a>
          </li>
          <li class="flex justify-center items-center">
            <a
              href="/blog"
              class="flex gap-2 justify-center items-center transition-all duration-300 hover:text-secondary-darker"
              title="blog"
            >
              <font-awesome-icon icon="fa-solid fa-book-open" />
              Blog
            </a>
          </li>
          <li class="flex justify-center items-center">
            <a
              href="/about"
              class="flex gap-2 justify-center items-center transition-all duration-300 hover:text-secondary-darker"
              title="about me"
            >
              <font-awesome-icon icon="fa-solid fa-circle-info" />
              About
            </a>
          </li>
          <li class="flex justify-center items-center">
            <a
              href="#contact"
              class="flex gap-2 justify-center items-center transition-all duration-300 hover:text-secondary-darker"
              title="about me"
            >
              <font-awesome-icon icon="fa-solid fa-envelope" />
              Contact
            </a>
          </li>

          <li class="flex justify-center items-center">
            <a
              href="#"
              class="flex gap-2 justify-center items-center transition-all duration-300 hover:text-secondary-darker"
              title="Up"
              :class="transparent ? 'hidden' : 'flex'"
            >
              <font-awesome-icon icon="fa-solid fa-arrow-up" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
