import revive_payload_client_4sVQNw7RlN from "/home/portfolio/actions-runner/_work/portfolio/portfolio/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/portfolio/actions-runner/_work/portfolio/portfolio/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/portfolio/actions-runner/_work/portfolio/portfolio/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/portfolio/actions-runner/_work/portfolio/portfolio/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/portfolio/actions-runner/_work/portfolio/portfolio/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/portfolio/actions-runner/_work/portfolio/portfolio/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/portfolio/actions-runner/_work/portfolio/portfolio/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/portfolio/actions-runner/_work/portfolio/portfolio/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/home/portfolio/actions-runner/_work/portfolio/portfolio/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_BqLkNUef68 from "/home/portfolio/actions-runner/_work/portfolio/portfolio/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/portfolio/actions-runner/_work/portfolio/portfolio/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import font_awesome_LdkJbJgHQx from "/home/portfolio/actions-runner/_work/portfolio/portfolio/src/plugins/font-awesome.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_BqLkNUef68,
  chunk_reload_client_UciE0i6zes,
  font_awesome_LdkJbJgHQx
]