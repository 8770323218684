<script setup lang="ts">
import Grid from '@components/Grid.vue'
import Block from '@components/Block.vue'
import Container from '@components/Container.vue'
import InViewObserver from '../../observers/InViewObserver'
import type { Intersection } from '../../observers/InViewObserver'
import { onMounted, ref } from 'vue'

const target = ref<Element>()
const visible = ref(false)

withDefaults(defineProps<{ additionalClass?: String }>(), {})

const observer = new InViewObserver(
  (intersection: Intersection) => {
    if (!visible.value) {
      visible.value = intersection.visible
    }
  },
  '#app',
  false
)

onMounted(() => {
  observer.observe(target.value as Element)
})
</script>

<template>
  <Container :class="additionalClass">
    <Block>
      <Grid className="relative" id="about">
        <div
          ref="target"
          class="col-span-full order-1 md:order-1 rounded-md overflow-hidden left-8 md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-5"
        >
          <slot name="left"> </slot>
        </div>

        <div
          class="col-span-full md:col-span-3 md:col-start-4 lg:col-span-5 lg:col-start-8 order-2 md:order:3 lg:pt-12 duration-700 delay-300"
          :class="visible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-56'"
        >
          <div class="relative">
            <div class="rounded-md text-white py-16p min-h-10 md:min-h-[20rem] lg:min-h-[28rem]">
              <span class="text-secondary uppercase font-heading tracking-wide"
                ><slot name="subheading"></slot
              ></span>
              <h2 class="font-sectionheading text-sectionheading lg:text-6xl">
                <slot name="heading"></slot>
              </h2>
              <div class="font-body text-body mt-4 md:mt-10 lg:mt-12 mb-2">
                <slot name="text"></slot>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-full order-3"><slot name="footer"></slot></div>
      </Grid>
    </Block>
  </Container>
</template>
