<script setup lang="ts">
import Block from '../Block.vue'
import Grid from '../Grid.vue'
import Container from '@components/Container.vue'
import BlogItem from '../cards/BlogItem.vue'
import { onMounted, watch, ref } from 'vue'
import { useAlgoliaSearch } from '#imports'
import { useRuntimeConfig } from '#imports'
import LoadingBlogItem from '../skeleton/LoadingBlogItem.vue'
import GlowEffectTracking from '../../observers/GlowEffectTracking'

const config = useRuntimeConfig()

const glowTracking = ref()
const target = ref()
const searchResult = ref()
const { result, search } = useAlgoliaSearch(config.public.algoliaIndex)

onMounted(async () => {
  searchResult.value = await search({
    query: '',
    requestOptions: {
      facetFilters: ['meta.category:blog']
    }
  })
})
</script>

<template>
  <div class="w-full" ref="target">
    <Container className="">
      <div class="w-full relative glow-capture">
        <div class="w-full">
          <div class="group flex justify-center">
            <Block className="w-full ">
              <Grid id="news">
                <div class="col-span-full flex flex-col md:flex-row md:items-center">
                  <div class="flex-1">
                    <span class="text-secondary text-lg uppercase tracking-wide font-heading"
                      >Latest</span
                    >
                    <h2
                      id="latest"
                      class="font-sectionheading text-heading lg:text-7 xl:text-8xl uppercase mb-6 relative text-white md:mb-8 lg:mb-14"
                    >
                      Entries
                    </h2>
                  </div>
                  <div
                    class="flex visible items-end justify-end md:items-center md:justify-center relative"
                  >
                    <a
                      class="text-white uppercase group/link flex pr-6 flex-row items-center tracking-widest font-semibold text-sm md:text-lg gap-2 hover:mr-2 duration-300 glow:opacity-0 hover:text-secondary"
                      href="/blog"
                      >Read them all
                      <font-awesome-icon
                        icon="fa-solid fa-arrow-right"
                        class="absolute right-0 opacity-0 group-hover/link:opacity-100"
                      />
                      <font-awesome-icon
                        icon="fa-solid fa-chevron-right"
                        class="absolute right-0 opacity-100 group-hover/link:opacity-0 duration-200"
                      />
                    </a>
                  </div>
                </div>
                <div
                  class="col-span-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2"
                >
                  <div
                    v-for="(item, index) in searchResult?.hits
                      ?.filter((item) => item.meta?.draft)
                      .slice(0, 3)"
                    class="grid-span-full md:grid-span-1 lg:grid-span-1"
                    :key="index"
                  >
                    <BlogItem
                      :title="item.meta.title"
                      :image="item.meta.image?.trim()"
                      :tags="item.meta.categories?.split(',')"
                      :description="item.meta.description"
                      :path="item.path"
                      :draft="false"
                    >
                    </BlogItem>
                    <LoadingBlogItem v-if="!searchResult"></LoadingBlogItem>
                    <LoadingBlogItem v-if="!searchResult"></LoadingBlogItem>
                  </div>
                </div>
              </Grid>
            </Block>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
