<script setup lang="ts">
import InViewObserver from '../../observers/InViewObserver'
import type { Intersection } from '../../observers/InViewObserver'
import { ref, onMounted } from 'vue'

const visible = ref<boolean>(false)
const open = ref<boolean>(false)
const showHistory = ref<boolean>(false)

const onScroll = (intersection: Intersection) => {
  open.value = false
  if ((intersection.scrollPositionPercentage ?? 0) > 12) {
    visible.value = true
  } else {
    visible.value = false
  }
}

const observer = new InViewObserver((intersection: Intersection) => {}, '#top', true, onScroll)

const toggleOpen = () => {
  open.value = !open.value
}

onMounted(() => {
  if (!process.server) {
    const element = document.querySelector('#top')
    if (element) {
      observer.observe(element)
    }
  }

  if (window.history.length > 1 && document.referrer.indexOf(window.location.host) !== -1) {
    showHistory.value = true
  }
})
</script>
<template>
  <div
    class="fixed top-[60%] md:top-5 z-[99] right-0 w-auto duration-300 mr-4 md:mr-8 lg:mr-14 xl:mr-24 5xl:mr-44 lg:hidden"
    :class="
      visible ? 'opacity-100 translate-x-0 5xl:-translate-x-[20vw]' : 'opacity-0 translate-x-16'
    "
  >
    <div
      class="bg-white border-white/10 border rounded-lg hover:opacity-95"
      :class="open ? 'opacity-95' : 'opacity-65'"
    >
      <div
        @click="toggleOpen()"
        class="h-10 w-10 cursor-pointer flex justify-center items-center relative text-primary"
      >
        <font-awesome-icon
          icon="fa-solid fa-burger"
          class="absolute transition-all duration-300"
          :class="open ? 'opacity-0 scale-0 -rotate-90' : 'opacity-100 rotate-0 scale-1'"
        />
        <font-awesome-icon
          icon="fa-solid fa-xmark"
          class="absolute transition-all duration-300"
          :class="open ? 'opacity-100 rotate-0' : 'opacity-0 scale-0 rotate-90'"
        />
      </div>
      <nav class="overflow-hidden duration-500 h-auto" :class="open ? 'max-h-52' : 'max-h-0'">
        <ul class="gap-4 flex-col flex py-4">
          <li class="flex justify-center items-center" v-if="showHistory">
            <a href="javascript:history.back()" title="back" class="text-primary"
              ><font-awesome-icon icon="fa-solid fa-arrow-left"
            /></a>
          </li>
          <li class="flex justify-center items-center">
            <a href="/" class="text-primary" title="home"
              ><font-awesome-icon icon="fa-solid fa-house"
            /></a>
          </li>
          <li class="flex justify-center items-center">
            <a href="/blog" class="text-primary" title="blog">
              <font-awesome-icon icon="fa-solid fa-book-open"
            /></a>
          </li>
          <li class="flex justify-center items-center">
            <a href="/about" class="text-primary" title="about me">
              <font-awesome-icon icon="fa-solid fa-circle-info"
            /></a>
          </li>
          <li class="flex justify-center items-center">
            <a href="#" class="text-primary" title="up">
              <font-awesome-icon icon="fa-solid fa-arrow-up" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
